
const Project = ({name,description,imgURL,demoLink,repoLink}) => {
  

    return (
        <div className="project">
        <div className="project-intro">
            <h3>{name}</h3>
            <a href={repoLink} className="project-links"><i
                    className="fab fa-github-square"></i></a>
            <a href={demoLink} className="project-links"><i
                    className="fas fa-link"></i></a>
        </div>
        <p>{description}</p>
        <img src={imgURL} alt={`Project ${name}`}/>
    </div>
      
    )
}

export default Project
