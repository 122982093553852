export const projects = [
  {
    name: "PR1ORITI23",
    description: "A task organizer to manage your daily todo lists",
    imgURL: "/img/prioritize.png",
    demoLink: "/projects/prioritize/",
    repoLink: "https://github.com/clickwithclark/prioritize#readme",
  },
  {
    name: "MONEY MI$ER",
    description:
      "Developing a healthy habit of budgeting regularly as well as expense tracking throughout your day",
    imgURL: "/img/money-miser.png",
    demoLink: "/projects/money-miser/",
    repoLink: "https://github.com/clickwithclark/money-miser#readme",
  },
  {
    name: "FFV",
    description:
      "The Fast Form Validator is a streamlined solution to validate input fields",
    imgURL: "/img/ffv.png",
    demoLink: "https://codepen.io/clickwithclark/full/zYzWeyK",
    repoLink: "https://github.com/clickwithclark/fast-form-validator#readme",
  },
];
