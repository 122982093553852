import { initializeEventListeners } from "../js/mobile-menu.js";
import Projects from './Projects.jsx'; 

initializeEventListeners();
const Main = ({projectList}) => {
    return (
     <main>
        <section className="introduction">
            <div className="intro">
                <div className="intro-text">
                    <h1 className="intro-text__header">Meet Clark! </h1>
                    <div className="intro-text__paragraph">
                        <p>I am a Trinbagonian front end developer with a background in public service who believes that
                            user-centered development, teamwork, and strong communication is the recipe for quality
                            software
                            and satisfied customers.</p>



                    </div>
                </div>
                <div className="intro-card">

                    <img src="./img/portrait.png" alt="portrait" className="intro-card__portrait"/>

                </div>

                <div className="detailed-intro">
                    <p>I work largely with JavaScript and React, Bootstrap and Node to build online user
                        experiences. I
                        approach software development by clarifying the problem at hand and with research and
                        stepwise
                        refinement I prepare a strategy to solve it.</p>

                    <p>When I'm not programming or investigating new technologies, you can find me playing a video
                        game,
                        watching a TV series, or summoning courage to finish a Stephen King novel.</p>

                    <p> Want to know more about my work? While all my projects have an active demo, the
                        repository is
                        usually kept private, if you would email me, I will be sure to make it available for your
                        perusal.</p>
                </div>
            </div>
        </section>

   <Projects projectList={projectList}/>
        
        <section id="contact-me" className="contact-me">
            <h3 className="contact-me__heading"> Contact Me</h3>
            <form action="https://formspree.io/f/mjvjyknb" method="POST">
            <div className="contact-me__container">
                    <div className="contact-me__details">
                        <input type="text" name="name" required placeholder="Name"/>
                        <input type="email" required name="_replyto" placeholder="Email Address"/>
                        <input type="text" required name="subject" placeholder="Subject"/>
                    </div>
                    <div className="contact-me__message">
                        <textarea rows="10" required name="message" placeholder="Message"></textarea>
                    </div>
                    <hr className="break"/>
                    <button className="contact-me__btn">Send</button>
                </div>

            </form>
            <div className="contact-me__meeting-graphic"></div>
        </section>
        
        

     </main>
    )
}

export default Main
