import Header from './Header.jsx'
import Main from './Main.jsx'
import { projects as projectList } from "../js/project-list.js";
const App = () => {
  return (
    <>
    <Header />
    <Main projectList={projectList}/>   
    </>
  )
}

export default App

