import React from 'react'

const Header = () => {
    return (
        
         <header className="navbar">

<div className="logo">
    <img src="./img/logo2.png" alt="site logo"/>

</div>
<nav>
    <ul className="nav__list">
        <li className="nav__list-item"><a href="#projects">Projects</a></li>
        <li className="nav__list-item"><a href="#contact-me">Contact Me</a></li>
        <li className="nav__list-item"><a href="index.html">Resume</a></li>
        <li className="nav__list-item"><a href="https://github.com/clickwithclark">Github</a></li>
    </ul>
</nav>
<div className="menu-btn"> <span className="icon menu"></span></div>
</header>
            
        
    )
}

export default Header
