import React,{useState} from 'react'
import Project from './Project.jsx'
const Projects = ({projectList}) => {
    return (
        <section className="projects">
        <h2 id="projects" className="projects__header">Projects</h2>

        <div className="my-projects">
        {projectList.map((project,index) => <Project key={index} {...project} /> 
     )}

        </div>
    </section>
    )
}

export default Projects
